import {editionsPath} from '@/hooks/navigation/utils';
import type {I18nRequestContext} from '@/types';
import {localizePathBySite} from '@/utils/site';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getTopicsNav({t, site}: I18nRequestContext) {
  const whatIsUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/o-que-e-shopify';
      case 'de':
        return '/de/blog/shopsystem';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/tutorial-shopify';
      case 'de-BE':
        return '/de/blog/shopsystem';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/shopify-francais';
      case 'it':
        return '/it/blog/come-funziona-shopify';
      case 'nl':
      case 'nl-BE':
        return '/nl/blog/wat-is-shopify';
      case 'en-DK':
      case 'en-NO':
        return '/blog/what-is-shopify';
      default:
        return localizePathBySite('/blog/what-is-shopify', site);
    }
  };

  const storiesUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/topics/histórias-de-sucesso';
      case 'de':
        return '/de/blog/die-hoehle-der-loewen';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/topics/historias-de-éxito';
      case 'de-BE':
        return '/de/blog/die-hoehle-der-loewen';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/topics/success-story';
      case 'it':
        return '/it/blog/topics/storie-di-successo';
      case 'ja':
        return '/jp/blog/success-story-usaginonedoko';
      case 'nl':
      case 'nl-BE':
        return '/nl/blog/50-inspirerende-shopify-webshops';
      case 'en-DK':
      case 'en-NO':
        return '/blog/topics/founder-stories';
      default:
        return localizePathBySite('/blog/topics/founder-stories', site);
    }
  };

  const brandingUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/como-construir-sua-propria-marca-do-zero';
      case 'de':
        return '/de/blog/branding';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/como-construir-una-marca-que-haga-crecer-tu-negocio';
      case 'de-BE':
        return '/de/blog/branding';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/comment-creer-une-marque-le-guide-en-7-etapes';
      case 'it':
        return '/it/blog/come-creare-da-zero-un-brand-in-7-passi';
      case 'nl':
      case 'nl-BE':
        return '/nl/blog/naamsbekendheid-bouw-eigen-merk';
      case 'en-DK':
      case 'en-NO':
        return '/blog/how-to-build-a-brand';
      default:
        return localizePathBySite('/blog/how-to-build-a-brand', site);
    }
  };

  const marketingUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/113824069-guia-de-criacao-de-plano-de-marketing-em-3-passos';
      case 'de':
        return '/de/blog/topics/online-marketing';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/18108960-como-escribir-un-plan-de-marketing-perfecto-para-tu-tienda-online';
      case 'de-BE':
        return '/de/blog/topics/online-marketing';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/le-guide-pour-elaborer-un-plan-marketing-e-commerce-tres-efficace-en-3-etapes';
      case 'it':
        return '/it/blog/strategia-di-marketing';
      case 'en-DK':
      case 'en-NO':
        return '/blog/marketing-plan';
      default:
        return localizePathBySite('/blog/marketing-plan', site);
    }
  };

  const ecommUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/guia-seo-ecommerce';
      case 'de':
        return '/de/blog/seo-onlineshops';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/seo-para-ecommerce';
      case 'de-BE':
        return '/de/blog/seo-onlineshops';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/seo-et-referencement-pour-les-debutants';
      case 'it':
        return '/it/blog/guida-seo-per-ecommerce-principianti';
      case 'nl':
      case 'nl-BE':
        return '/nl/blog/webshop-seo-beginners';
      case 'en-DK':
      case 'en-NO':
        return '/blog/ecommerce-seo-beginners-guide';
      default:
        return localizePathBySite('/blog/ecommerce-seo-beginners-guide', site);
    }
  };

  const socialUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/topics/redes-sociais';
      case 'de':
        return '/de/blog/social-media-marketing';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/topics/social-media';
      case 'de-BE':
        return '/de/blog/social-media-marketing';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/topics/réseaux-sociaux';
      case 'it':
        return '/it/blog/social-media-marketing';
      case 'ja':
        return '/jp/blog/social-media-marketing-strategy';
      case 'nl':
      case 'nl-BE':
        return '/nl/blog/social-media-marketing';
      case 'en-DK':
      case 'en-BE':
      case 'en-NO':
      case 'en-ID':
      case 'en-PH':
      case 'en-ZA':
      case 'en-HK':
        return '/blog/social-media-marketing-strategy';
      default:
        return localizePathBySite(
          '/blog/topics/social-media-optimization',
          site,
        );
    }
  };

  const growthUrl = () => {
    switch (site.locale) {
      case 'pt-BR':
        return '/br/blog/business-model-canvas';
      case 'de':
        return '/de/blog/moebel-online-verkaufen';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es/blog/shopify-plus-plataforma-escalable';
      case 'de-BE':
        return '/de/blog/moebel-online-verkaufen';
      case 'fr':
      case 'fr-BE':
        return '/fr/blog/growth-hacking';
      case 'it':
        return '/it/blog/growth-hacking-italia';
      case 'ja':
        return '/jp/blog/ecommerce-business-blueprint';
      case 'en-DK':
      case 'en-NO':
        return '/blog/how-to-scale-small-business';
      default:
        return localizePathBySite('/blog/how-to-scale-small-business', site);
    }
  };

  const navItems = [];
  const shopifyDescriptionEnabled = ![
    'da',
    'sv',
    'ko',
    'zh-HK',
    'zh-TW',
  ].includes(site.locale);

  if (shopifyDescriptionEnabled) {
    navItems.push({
      content: t('nav:whatIsShopify'),
      url: whatIsUrl(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'What is Shopify?',
      },
      description: t('nav:whatIsShopifyDescription'),
    });
  }

  if (site.features.includes('editions')) {
    navItems.push({
      content: t('nav:shopifyEditions'),
      url: editionsPath(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Editions',
      },
      description: t('nav:shopifyEditionsDescription'),
    });
  }

  if (site.features.includes('blog') && site.locale !== 'zh-CN') {
    // blog post does not exist for nl (Dutch)
    !['nl', 'nl-BE'].includes(site.locale) &&
      navItems.push({
        content: t('nav:founderStories'),
        url: storiesUrl(),
        data: {
          gaEvent: MAIN_NAV_GA_EVENT,
          gaAction: 'Founder Stories',
        },
        description: t('nav:founderStoriesDescription'),
      });
    navItems.push({
      content: t('nav:branding'),
      url: brandingUrl(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Branding',
      },
      description: t('nav:brandingDescriptionV2'),
    });
    if (!['nl', 'nl-BE'].includes(site.locale)) {
      navItems.push({
        content: t('nav:marketing'),
        url: marketingUrl(),
        data: {
          gaEvent: MAIN_NAV_GA_EVENT,
          gaAction: 'Marketing',
        },
        description: t('nav:marketingDescription'),
      });
    }
    navItems.push({
      content: t('nav:ecommerceSeo'),
      url: ecommUrl(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Ecommerce SEO',
      },
      description: t('nav:ecommerceSeoDescription'),
    });
    navItems.push({
      content: t('nav:socialMediaStrategy'),
      url: socialUrl(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Social media strategy',
      },
      description: t('nav:socialMediaStrategyDescription'),
    });
    if (!['nl', 'nl-BE'].includes(site.locale)) {
      navItems.push({
        content: t('nav:businessGrowth'),
        url: growthUrl(),
        data: {
          gaEvent: MAIN_NAV_GA_EVENT,
          gaAction: 'Business growth',
        },
        description: t('nav:businessGrowthDescription'),
      });
    }
  }

  // remove it if for zh-CN will be able all blog links
  if (site.locale === 'zh-CN') {
    navItems.push({
      content: t('nav:socialMediaStrategy'),
      url: socialUrl(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Social media strategy',
      },
      description: t('nav:socialMediaStrategyDescription'),
    });
  }

  // In case if we don`t have any items we return
  // just params for correct block bluilding and remove titles
  if (navItems.length === 0) {
    return {
      columnStart: 4,
      columnSpan: 6,
    };
  }

  return {
    content: t('nav:popularTopics'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Popular topics Nav',
    },
    columnStart: 4,
    columnSpan: 6,
    splitColumns: true,
    icon: 'popularTopics',
    navItems,
  };
}
