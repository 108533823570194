import {localizePathBySite} from '@/utils/site';
import {inboxPathAlt} from '@/hooks/navigation/utils';
import type {I18nRequestContext} from '@/types';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getMarketNav({t, site}: I18nRequestContext) {
  const navItems = [
    {
      content: t('nav:marketDescription'),
      url: localizePathBySite('/marketing', site),
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Market your business',
      },
      description: t('nav:marketDescriptionV2'),
    },
    {
      content: t('nav:socialV2'),
      url: localizePathBySite('/facebook-instagram', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Market across social',
      },
      description: t('nav:socialDescription'),
    },
  ];

  const inboxAllowListLocales: string[] = [
    'en',
    'en-BE',
    'nl',
    'nl-BE',
    'fr-BE',
    'da',
    'sv',
    'en-DK',
    'de-BE',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-MY',
    'en-ZA',
    'en-NG',
    'es',
    'es-CO',
    'es-MX',
    'ko',
    'zh-CN',
    'zh-HK',
    'en-HK',
    'zh-TW',
  ];

  if (inboxAllowListLocales.includes(site.locale)) {
    navItems.push({
      content: t('nav:inboxV2'),
      url: inboxPathAlt(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'inbox',
      },
      description: t('nav:inboxDescriptionV2'),
    });
  }

  navItems.push(
    {
      content: t('nav:shopifyEmail'),
      url: localizePathBySite('/email-marketing', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Shopify Email',
      },
      description: t('nav:shopifyEmailDescription'),
    },
    {
      content: t('nav:customerInsights'),
      url: localizePathBySite('/segmentation', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Know your customers',
      },
      description: t('nav:customerInsightsDescription'),
    },
  );

  return {
    content: t('nav:market'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Market Nav',
    },
    columnStart: 7,
    columnSpan: 3,
    icon: 'market',
    navItems,
  };
}
